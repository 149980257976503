import { ThemeProvider } from '@emotion/react';
import { createTheme, PaletteMode, ThemeOptions } from '@mui/material';
import React, { useContext } from 'react';
import '@fontsource/poppins/600.css';
import '@fontsource/open-sans/400.css';
import '@fontsource/open-sans/600.css';
import '@fontsource/open-sans/700.css';
import { UIContext } from './UIContext';

export const getDesignTokens = (mode: PaletteMode): ThemeOptions => ({
  typography: {
    fontFamily: ['Open Sans', 'sans-serif'].join(','),
    fontSize: 14,
    h1: {
      fontSize: '2rem',
      fontWeight: 600,
      fontFamily: ['Poppins', 'sans-serif'].join(','),
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: 600,
      fontFamily: ['Poppins', 'sans-serif'].join(','),
    },
    h3: {
      fontSize: '1.125rem',
      fontWeight: 600,
      fontFamily: ['Poppins', 'sans-serif'].join(','),
    },
    h4: {
      fontSize: '1rem', // @todo this can't really be smaller than the main text, right?
      fontWeight: 600,
      fontFamily: ['Poppins', 'sans-serif'].join(','),
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 400,
    },
    body2: {
      fontSize: '0.875rem',
      fontWeight: 400,
    },
    subtitle1: {
      fontSize: '1rem',
      fontWeight: 600,
      lineHeight: 1.5,
    },
    button: {
      fontSize: '1rem',
      fontWeight: 600,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
          textTransform: 'none',
        },
      },
    },
    ...(mode === 'dark' && {
      MuiTypography: {
        styleOverrides: {
          root: {
            color: '#f5f7fc',
          },
        },
      },
    }),
  },
  palette: {
    mode,
    ...(mode === 'light'
      ? {
          text: {
            primary: '#041123', // Primary 80
            secondary: '#575766', // Primary 60
            disabled: '#0000008A',
          },
          action: {
            disabled: '#00000042', // Primary 60 (has to pass a11y)
          },
          primary: {
            main: '#00b5fa',
          },
          secondary: { main: '#013BAE0A', dark: '#fff' },
          error: { main: '#D32F2F', light: '#FBEAEA', dark: '#541313' },
          success: { main: '#2E7D32', light: '#eaf2ea', dark: '#123214' },
          warning: { main: '#ED6C02', light: '#fdf0e6', dark: '#5f2b01' },
          info: { main: '#013BAE', light: '#e6ebf7', dark: '#001846' },
          background: {
            default: '#FFF',
          },
          grey: {
            '50': '#FAFAFA',
            '100': '#0000000A',
            '200': '#0000001F',
            '300': '#00000099',
          },
        }
      : {
          text: {
            primary: '#f5f7fc', // Primary 80
            secondary: '#e0e0e0', // Primary 60
            disabled: '#d1d1d1',
          },
          action: {
            disabled: '#00000042', // Primary 60 (has to pass a11y)
          },
          primary: {
            main: '#fff',
          },
          secondary: { main: '#2b2b2a', dark: '#000' },
          error: { main: '#FF1800', light: '#FBEAEA', dark: '#541313' },
          success: { main: '#3DBF44', light: '#eaf2ea', dark: '#123214' },
          warning: { main: '#ED6C02', light: '#fdf0e6', dark: '#5f2b01' },
          info: { main: '#013BAE', light: '#e6ebf7', dark: '#001846' },
          background: {
            default: '#2b2b2a',
          },
          grey: {
            '50': '#FAFAFA',
            '100': '#aaa',
            '200': '#4f4e4e',
            '300': '#ccc',
          },
        }),
  },
});

const ThemeContext = ({ children }: { children: React.ReactNode }) => {
  const ui = useContext(UIContext);
  const designTokens = getDesignTokens(ui.colorMode ?? 'light');

  document.body.style.backgroundColor = designTokens.palette?.background?.default ?? '#fff';
  document.body.style.margin = '0px';

  return <ThemeProvider theme={createTheme(designTokens)}>{children}</ThemeProvider>;
};

export default ThemeContext;
