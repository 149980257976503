import React, {ReactNode, useContext} from 'react';
import {styled} from '@mui/material/styles';

import List from '@mui/material/List';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenOutlinedIcon from '@mui/icons-material/MenuOpenOutlined';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {Link as RouterLink, useLocation} from 'react-router-dom';
import Link from '@mui/material/Link';
import SvgIcon from '@mui/material/SvgIcon';
import {ReactComponent as Logo} from '../img/locked-logo-small.svg';
import {slugify} from '../helpers/helpers';
import {UIContext} from '../context/UIContext';
import SideBar from './SideBar';
import {NAV_CLOSED_DRAWER_WIDTH, NAV_DRAWER_WIDTH} from '../constants';
import { UserContext } from '../context/UserContext';

const DrawerHeader = styled(Box)(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    borderBottom: `1px ${theme.palette.grey[200]} solid`,
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

export interface SideLink {
    text: string;
    path: string;
    icon?: ReactNode;
    children?: SideLink[];
    hidden?: boolean;
}

export interface SideNavProps {
    links?: SideLink[];
}

const getNavLinks = (links:SideLink[] | undefined, userNavLinks: SideLink[]):SideLink[] => {
    if (links) return links;
    return userNavLinks;
}


const SideNav = (props: SideNavProps) => {
    const {links: plinks} = props;
    const {navItems} = useContext(UserContext);
    const links = getNavLinks(plinks, navItems);
    
    const location = useLocation();
    const {isMobile, openSideNav, toggleDrawer} = useContext(UIContext);

    const drawerContent = (
        <>
            <DrawerHeader>
                <IconButton
                    sx={{
                        padding: {xs: '12px', sm: '16px'},
                        margin: openSideNav ? 'none' : 'auto',
                        marginLeft: openSideNav && isMobile ? '8px' : '4px',
                        marginRight: '4px',
                    }}
                    onClick={() => toggleDrawer?.(!openSideNav)}
                    color="inherit"
                    title="Open Drawer"
                >
                    {openSideNav ? <MenuOpenOutlinedIcon/> : <MenuIcon/>}
                </IconButton>

                {openSideNav && (
                    <Link
                        href="/"
                        height="1.5em"
                    >
                        <SvgIcon inheritViewBox sx={{width: '92px', height: '25px'}}>
                            <Logo/>
                        </SvgIcon>
                    </Link>
                )}
            </DrawerHeader>

            <List aria-label="nav menu items">
                {links.filter((link) => !link.hidden).map((link) => {
                    let isCurrentSection = location.pathname.includes(link.path);
                    let isCurrent =
                        link.path === '/'
                            ? location.pathname === link.path
                            : location.pathname.includes(link.path);
                    if (link.path.includes('/security')) {
                        isCurrentSection = location.pathname.includes('/security');
                        isCurrent = !openSideNav && isCurrentSection;
                    }
                    const listItems: React.ReactElement[] = [];
                    listItems.push(
                        <ListItem
                            key={`sidenav-item-${slugify(link.text)}`}
                            disablePadding
                            sx={{
                                display: 'block',
                                borderLeft: isCurrent ? '3px solid' : 'none',
                                borderColor: 'primary.main',
                            }}
                        >
                            <Link
                                component={RouterLink}
                                underline="none"
                                to={link.path}
                                color="inherit"
                                aria-label={`link for ${link.text}`}
                            >
                                <ListItemButton
                                    tabIndex={-1}
                                    title={link.text}
                                    onClick={() => isMobile && toggleDrawer(false)}
                                    sx={{
                                        padding: '8px 16px',

                                        backgroundColor: isCurrent ? 'secondary.main' : 'secondary.dark',
                                        ...(openSideNav && {
                                            marginRight: '16px',
                                            borderRadius: '0 40px 40px 0',
                                            paddingLeft: isCurrent ? '17px' : '20px',
                                            justifyContent: 'normal',
                                        }),
                                        ...(!openSideNav && {
                                            width: '48px',
                                            height: '48px',
                                            marginRight: '8px',
                                            marginLeft: isCurrent ? '5px' : '8px',
                                            borderRadius: '40px',
                                            justifyContent: 'center',
                                        }),
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: openSideNav ? 3 : 'auto',

                                            color: isCurrent ? 'primary.main' : 'text.disabled',
                                        }}
                                    >
                                        {link.icon}
                                    </ListItemIcon>
                                    {openSideNav && (
                                        <ListItemText
                                            primary={link.text}
                                            primaryTypographyProps={{
                                                variant: isCurrent ? 'subtitle1' : 'body1',
                                            }}
                                            sx={{
                                                color: isCurrent ? 'primary.main' : 'text.primary',
                                            }}
                                        />
                                    )}
                                </ListItemButton>
                            </Link>
                        </ListItem>
                    );
                    if (openSideNav && isCurrentSection) {
                        link.children?.forEach((sublink) => {
                            const isActiveSubLink = location.pathname === sublink.path;
                            listItems.push(
                                <ListItem
                                    key={`sidenav-item-sublink-${slugify(sublink.text)}`}
                                    disablePadding
                                    sx={{
                                        display: 'block',
                                        borderLeft: isActiveSubLink
                                            ? (theme) => `2px ${theme.palette.primary.main} solid`
                                            : (theme) => `2px ${theme.palette.grey[200]} solid`,
                                        marginLeft: '31px',
                                        width: 'inherit',
                                    }}
                                >
                                    <Link
                                        component={RouterLink}
                                        underline="none"
                                        to={sublink.path}
                                        color="inherit"
                                        aria-label={`sublink for ${sublink.text}`}
                                    >
                                        <ListItemButton
                                            tabIndex={-1}
                                            title={sublink.text}
                                            onClick={() => isMobile && toggleDrawer(false)}
                                            sx={{
                                                padding: '6px 16px',
                                                backgroundColor: isActiveSubLink ? 'secondary.main' : 'secondary.dark',
                                                marginRight: '16px',
                                                borderRadius: '0 40px 40px 0',
                                                paddingLeft: '35px',
                                                justifyContent: 'normal',
                                            }}
                                        >
                                            <ListItemText
                                                primary={sublink.text}
                                                primaryTypographyProps={{
                                                    variant: isActiveSubLink ? 'subtitle1' : 'body1',
                                                }}
                                                sx={{
                                                    color: isActiveSubLink ? 'primary.main' : 'text.primary',
                                                }}
                                            />
                                        </ListItemButton>
                                    </Link>
                                </ListItem>
                            );
                        });
                    }
                    return listItems.map((element) => element);
                })}
            </List>
        </>
    );

    return (
        <Box sx={{flexShrink: {sm: 0}}} aria-label="navigation drawer" component="nav">
            <SideBar
                open={openSideNav}
                toggleDrawer={toggleDrawer}
                showSwipeableDrawer={isMobile}
                width={{
                    drawerWidth: NAV_DRAWER_WIDTH,
                    closedDrawerWidth: NAV_CLOSED_DRAWER_WIDTH,
                }}
            >
                {drawerContent}
            </SideBar>
        </Box>
    );
};
export default SideNav;
