import {CSSProperties} from "react";

const SUCCESS_MESSAGE_TIME = 3000;
const NAV_DRAWER_WIDTH = 296;
const NAV_CLOSED_DRAWER_WIDTH = 64;
const ADDRESS_DRAWER_WIDTH = 320;
const MD_WIDTH = 900;
const ADDRESSES_PER_PAGE = 3;

const comboStyle: CSSProperties = {
    marginBottom: '18px',
    paddingBottom: '12px',
    height: '24px',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    width: '100%',
};

const tallComboStyle: CSSProperties = {
    ...comboStyle,
    marginBottom: '40px',
}

const desktopButtonStyle: CSSProperties = {
    height: '48px',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    zIndex: 99999,
    paddingLeft: '24px',
    paddingRight: '24px',
};
const mobileButtonStyle: CSSProperties = {
  height: '40px',
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: 600,
  zIndex: 99999,
  paddingLeft: '16px',
  paddingRight: '16px',
};
const desktopSecondaryButtonStyle: CSSProperties = {
  height: '48px',
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: 600,
  backgroundColor: '#ffffff',
  zIndex: 99999,
  paddingLeft: '24px',
  paddingRight: '24px',
};
const mobileSecondaryButtonStyle: CSSProperties = {
  height: '40px',
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: 600,
  backgroundColor: '#ffffff',
  zIndex: 99999,
  paddingLeft: '24px',
  paddingRight: '24px',
};

export {
    SUCCESS_MESSAGE_TIME,
    NAV_DRAWER_WIDTH,
    NAV_CLOSED_DRAWER_WIDTH,
    ADDRESS_DRAWER_WIDTH,
    MD_WIDTH,
    ADDRESSES_PER_PAGE,
    comboStyle,
    tallComboStyle,
    desktopButtonStyle,
    mobileButtonStyle,
    desktopSecondaryButtonStyle,
    mobileSecondaryButtonStyle
};
