import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Button, Card, CardContent, Container, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';
import { getRequests, RequestsType } from '../../api/requests';
import CircularIndeterminate from '../../components/Loading';
import MainHeader from '../../components/MainHeader';
import AlertMessage from '../../components/AlertMessage';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const Requests = () => {
  const { data: rows, status, refetch } = useQuery<RequestsType[]>('getRequests', getRequests);
  const navigate = useNavigate();

  useEffect(() => {
    refetch();
  }, [refetch]);
  if (rows) {
    return (
      <Container
        component="main"
        id="main"
        sx={{
          padding: 8,
          paddingTop: 10,
          marginLeft: 0,
          flexGrow: 1,
        }}
      >
        <AlertMessage type="info">
          This page currently shows the last 15 requests. This should change when we have pagination
          implemented :)
        </AlertMessage>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Request Target</StyledTableCell>
                <StyledTableCell align="right">Status</StyledTableCell>
                <StyledTableCell align="right">User Email</StyledTableCell>
                <StyledTableCell align="right">AcceptedBy</StyledTableCell>
                <StyledTableCell align="right">Valid until/for</StyledTableCell>
                <StyledTableCell align="right">View Request</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <StyledTableRow key={row.ID}>
                  <StyledTableCell component="th" scope="row">
                    {row.Target}
                  </StyledTableCell>
                  <StyledTableCell align="right">{row.Status}</StyledTableCell>
                  <StyledTableCell align="right">{row.User.email}</StyledTableCell>
                  <StyledTableCell align="right">{row.AcceptedBy}</StyledTableCell>
                  <StyledTableCell align="right">
                    {row.Status === 'Requested' ? row.ValidFor : row.ValidUntil}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <Button size="small" onClick={() => navigate(`/request/${row.ID}`)}>
                      View
                    </Button>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    );
  }
  return (
    <div className="App">
      {status === 'error' && <p>Error fetching data</p>}
      {status === 'loading' && <p>Fetching data...</p>}
    </div>
  );
};

export default Requests;

