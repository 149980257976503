import React, {useContext} from 'react';
import {CSSObject, styled, Theme} from '@mui/material/styles';

import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Drawer from '@mui/material/Drawer';

import {UIContext} from '../context/UIContext';

type Anchor = 'top' | 'left' | 'bottom' | 'right';

const openedMixin = (drawerWidth?: number): CSSObject => ({
  width: drawerWidth,
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme, closedDrawerWidth: number): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: closedDrawerWidth,
});

const DrawerStyled = styled(Drawer, {
  shouldForwardProp: (prop) =>
    prop !== 'open' && prop !== 'drawerWidth' && prop !== 'closedDrawerWidth',
})<{
  drawerWidth?: number;
  closedDrawerWidth: number;
  open?: boolean;
}>(({ theme, open, drawerWidth, closedDrawerWidth }) => ({
  width: drawerWidth,
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(drawerWidth),
    '& .MuiDrawer-paper': openedMixin(drawerWidth),
  }),
  ...(!open && {
    ...closedMixin(theme, closedDrawerWidth),
    '& .MuiDrawer-paper': closedMixin(theme, closedDrawerWidth),
  }),
}));

const SideBar = ({
  open = true,
  toggleDrawer,
  children,
  belowTopBar = false,
  showSwipeableDrawer = false,
  disableSwipe = false,
  width,
  anchor = 'left',
}: {
    open?: boolean;
    toggleDrawer: (opena: boolean) => void;
    children: JSX.Element;
    belowTopBar?: boolean;
    showSwipeableDrawer?: boolean;
    disableSwipe?: boolean;
    width: {
        drawerWidth?: number;
        closedDrawerWidth: number;
    };
    anchor?: Anchor;
}) => {
  const { iOS } = useContext(UIContext);

  return showSwipeableDrawer ? (
    <SwipeableDrawer
      anchor={anchor}
      open={open}
      onClose={() => toggleDrawer?.(false)}
      onOpen={() => toggleDrawer?.(true)}
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
      disableSwipeToOpen={disableSwipe}
      sx={{
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: width.drawerWidth,
        },
        ...(belowTopBar && {
          zIndex: 1000,
        }),
      }}
      ModalProps={{
        keepMounted: true,
      }}
    >
      {children}
    </SwipeableDrawer>
  ) : (
    <DrawerStyled
      anchor={anchor}
      variant="permanent"
      open={open}
      drawerWidth={width.drawerWidth}
      closedDrawerWidth={width.closedDrawerWidth}
      aria-label="drawer menu"
      id="permanent-drawer"
      sx={{
        ...(belowTopBar && {
          '& .MuiDrawer-paper': {
            zIndex: 1000,
          },
        }),
      }}
    >
      {children}
    </DrawerStyled>
  );
};

export default SideBar;
