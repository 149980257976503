import React, {useContext} from 'react';
import {
    Box,
    Button,
    Divider,
    IconButton,
    Link,
    ListItem,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Typography,
} from '@mui/material';
import {Logout} from '@mui/icons-material';
import {useTranslation} from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import {UIContext} from '../context/UIContext';
import {UserContext} from '../context/UserContext';

/**
 * Generates a PKCE flow code verifier: A high-entropy cryptographic random STRING using the unreserved
 * characters [A-Z] / [a-z] / [0-9] / "-" / "." / "*" / "~" from Section 2.3 of [RFC3986],
 * with a minimum length of 43 characters and a maximum length of 128 characters.
 * @returns code verifier
 */
const generateVerifier = () => {
    const toCharCodes = (arr: Uint8Array) => {
        const validChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        return arr.map((x) => validChars.charCodeAt(x % validChars.length));
    };

    const randomStr = (len: number) => {
        const arr = new Uint8Array(len);
        window.crypto.getRandomValues(arr);
        return String.fromCharCode(...Array.from(toCharCodes(arr)));
    };

    return randomStr(43);
};

const ProfileMenu = ({
                         open,
                         handleClose,
                         anchorEl,
                     }: {
    open: boolean;
    handleClose: () => void;
    anchorEl: null | HTMLElement;
}) => {
    const {t} = useTranslation();
    const {authenticatedUser} = useContext(UserContext);
    const {animationsDisabled} = useContext(UIContext);

    return (
        <Menu
            anchorEl={anchorEl}
            id="profile-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            MenuListProps={{
                disablePadding: true,
            }}
            transitionDuration={animationsDisabled ? 1 : 'auto'}
            PaperProps={{
                elevation: 0,
                sx: {
                    overflow: 'visible',
                    backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.10), rgba(255, 255, 255, 0.10))',
                    filter: (theme) => `drop-shadow(0px 2px 8px ${theme.palette.grey[400]})`,
                    mt: {xs: 2, sm: 2.5},
                    '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                    },
                    '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        backgroundImage:
                            'linear-gradient(rgba(255, 255, 255, 0.10), rgba(255, 255, 255, 0.10))',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                    },
                },
            }}
            transformOrigin={{horizontal: 'right', vertical: 'top'}}
            anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
        >
            <Box
                sx={{
                    paddingTop: 1,
                    paddingRight: 2,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                }}
            >
                <IconButton
                    title={t('topbar.userMenuClose')}
                    aria-label={t('topbar.userMenuClose')}
                    color="inherit"
                    size="small"
                    onClick={handleClose}
                    sx={{padding: 0}}
                >
                    <CloseIcon
                        sx={{
                            color: (theme) => `${theme.palette.grey[300]}`,
                        }}
                    />
                </IconButton>
            </Box>
            <ListItem>
                <Box sx={{flexDirection: 'column', textAlign: 'center', padding: 2, paddingTop: 0}}>
                    <Box sx={{paddingVertical: 2}}>
                        <Typography variant="h3" mb={1}>
                            {authenticatedUser?.email}
                        </Typography>
                        <Typography variant="body2">{authenticatedUser?.email}</Typography>
                    </Box>
           {/* 
                    <Box sx={{marginTop: 3}}>
                        <Button
                            component={Link}
                            target="_blank"
                            href={process.env.REACT_APP_LINK_MANAGE_ACCOUNT}
                            variant="outlined"
                            sx={{paddingX: 2, paddingY: 1.125}}
                            role="button"
                        >
                            {t('profileNav.manageAccount')}
                        </Button>
                    </Box> */}
                </Box>
            </ListItem>
           
           {/*  <Divider/>
            <MenuItem
                sx={{paddingX: 2, paddingY: 1.5}}
                title={t('profileNav.logOut')}
            >
                <ListItemText>{t('profileNav.logOut')}</ListItemText>
                <ListItemIcon sx={{justifyContent: 'flex-end'}}>
                    <Logout fontSize="small"/>
                </ListItemIcon>
            </MenuItem>  */}
        </Menu>
    );
};

export default ProfileMenu;
