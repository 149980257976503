const AUTH_TOKEN =
  'Basic TUJTNEVHNlFHWEUwTVM0RU1FT1lMTTNVMTdPQzdVNkE6WlYxRExXSDlRTExNTEtTRzhaSUdTSDM1UzZMVkg3UEY=';

export interface ErrorObject extends Error {
  errors: any;
  error?: string;
  code: number;
}

/* eslint-disable import/prefer-default-export */
export function apiFetch<T>(path = '/', body = {}, method = 'GET'): Promise<T> {
  let options: {
    method: string;
    body?: string;
    mode?: RequestMode;
    credentials?: RequestCredentials;
    redirect?: RequestRedirect;
  } = {
    method,
    mode: 'cors',
    credentials: 'include',
    redirect: 'manual',
  };
  if (method !== 'GET') {
    options = { ...options, body: JSON.stringify(body) };
  }
  const headers: { 'Content-Type': string } = {
    'Content-Type': 'application/json',
  };

  return fetch(path, {
    ...options,
    headers,
  }).then((response) => {
    if (response.type === 'opaqueredirect') {
      window.location.href = `/auth-redirect?url=${window.location.pathname}`;
    }
    if (!response.ok) {
      return response
        .json()
        .catch(() => {
          // Couldn't parse the JSON
          const errorObject: ErrorObject = {
            name: 'Error',
            message: 'Error in API call',
            errors: {
              general: {
                key: 'errorInternalServerErrorEmpty',
                message: 'Internal server error.',
              },
              fields: [],
            },
            code: 500,
          };

          throw errorObject;
        })
        .then((error) => {
          const errorObject: ErrorObject = {
            name: 'Error',
            message: 'Error in API call',
            error: error.error,
            errors: error.errors,
            code: error.code,
          };

          throw errorObject;
        });
    }
    // Successful response, parse the JSON and return the data
    return response
      .json()
      .catch((err) => {
        console.error(err);
      })
      .then((respBody) => (respBody && respBody.data ? respBody.data : respBody) as T);
  });
}

/* eslint-enable import/prefer-default-export */

