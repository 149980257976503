import React, {CSSProperties} from 'react';
import {Alert, AlertColor, IconButton} from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

export interface AlertMessageProps {
    action?: () => void;
    type: AlertColor;
    styles?: CSSProperties;
    children: React.ReactNode;
}

const AlertMessage = ({
                          action,
                          type,
                          styles,
                          children,
                      }: AlertMessageProps) => (
    <Alert
        variant="filled"
        severity={type}
        sx={{
            flexGrow: 1,
            fontWeight: 400,
            display: 'flex',
            alignItems: 'center',
            color: (theme) => theme.palette[type].dark,
            backgroundColor: (theme) => theme.palette[type].light,
      '.MuiAlert-message': {
        width: '100%',
      },
      ...styles,
    }}
    action={
      action && (
        <IconButton aria-label="close" color="inherit" size="small" onClick={action}>
          <CloseOutlinedIcon fontSize="inherit" />
        </IconButton>
      )
    }
  >
    {children}
  </Alert>
);

export default AlertMessage;
