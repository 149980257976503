/* eslint-disable import/prefer-default-export */
import { DateLocale } from 'yup/lib/locale';
import { User } from '../context/UserContext';
import { apiFetch } from './fetch';

export type RequestsType = {
  ID: number;
  CreatedAt: string;
  UpdatedAt: string;
  DeletedAt: string;
  UserID: number;
  User: User;
  AcceptedBy: string;
  Target: string;
  ValidUntil: string;
  ValidFor: string;
  Approved: boolean;
  Reason: string;
  Status: string;
};

export const getRequests = async (): Promise<RequestsType[]> => {
  const response = await apiFetch<RequestsType[]>(`/api/requests`);
  return response;
};

export const newRequest = async ({
  target,
  autoApprove,
  validFor,
  reason,
}: {
  target: string;
  autoApprove: boolean;
  validFor: string;
  reason: string;
}): Promise<RequestsType> => {
  const response = await apiFetch<RequestsType>(
    `/api/request/new`,
    { target, autoApprove, validFor, reason },
    'POST'
  );
  console.log(response);
  return response;
};

export const getRequest = async ({ id }: { id: string }): Promise<RequestsType> => {
  const response = await apiFetch<RequestsType>(`/api/request/${id}`);
  return response;
};

export const approveRequest = async ({ id }: { id: number }) => {
  const response = await apiFetch<RequestsType>(`/api/request/approve`, { requestID: id }, 'POST');
  return response;
};

