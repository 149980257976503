import mediaQuery from 'css-mediaquery';

export const slugify = (string: string) =>
  string
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '');

export const deepClone = (obj: unknown) => JSON.parse(JSON.stringify(obj));

export const initials = (name: string) =>
  name
    .match(/(^\S?|\s\S)?/g)
    ?.map((v) => v.trim())
    .join('')
    .match(/(^\S|\S$)?/g)
    ?.join('')
    .toLocaleUpperCase();

export const formatPrice = (value: number) => value.toFixed(2);

export const createMatchMedia = (width: number) => (query: string) => ({
  matches: mediaQuery.match(query, {
    width,
  }),
  addEventListener: jest.fn(),
  removeEventListener: jest.fn(),

  media: query,
  onchange: null,
  addListener: jest.fn(),
  removeListener: jest.fn(),
  dispatchEvent: jest.fn(),
});


