import React from 'react';
import {
    Typography,
    Card, Button, CardContent, CardActions, Divider
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import MainHeader from '../../components/MainHeader';

const NotFound = () => {
    const navigate = useNavigate();

    return (
        <Card variant="outlined">
            <CardContent sx={{
                padding: 3
            }}>
                <MainHeader
                    title="screens.error.notfound.title"
                    size="large"
                >
                    <Typography>screens.error.notfound.content</Typography>
                </MainHeader>
            </CardContent>
            <Divider />
            <CardActions sx={{
                padding: 2
            }}>
                <Button
                    color="primary"
                    variant="contained"
                    disableElevation
                    onClick={() => navigate(-1)}
                    sx={{
                        marginRight: 1
                    }}
                >
                    screens.error.notfound.backButton
                </Button>
                <Button color="primary" component={RouterLink} to="/">screens.error.notfound.homeButton</Button>
            </CardActions>
        </Card>
    );
};

export default NotFound;

