/* eslint-disable import/prefer-default-export */
import { apiFetch } from './fetch';
import { Permissions } from '../context/UserContext';
import { RequestsType } from './requests';

type Tenants = {
  id: number;
  userID: number;
  level: string;
  tenantName: string;
};

type User = {
  id: number;
  email: string;
  tenants: Tenants;
  role: string;
  accessRequests: RequestsType;
  // DatabaseAccess: DatabaseAccess;
};

type UserDetailResponse = {
  user: User;
  permissions: Permissions;
};

export const getUser = async () => {
  const response = await apiFetch<UserDetailResponse>(`/api/users/me`);
  return response;
};

