import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './screens/home/Home';
import NotFound from './screens/error/NotFound';
import Requests from './screens/requests/Requests';
import NewRequest from './screens/requests/New';
import RequestID from './screens/requests/id';

const AppRoutes = () => (
  <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/requests" element={<Requests />} />
    <Route path="/request/new/:target" element={<NewRequest />} />
    <Route path="/request/:id" element={<RequestID />} />
    <Route path="*" element={<NotFound />} />
  </Routes>
);

export default AppRoutes;

