import React, { useCallback, useMemo, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { PaletteMode, useMediaQuery } from '@mui/material';

export const getColorMode = (): PaletteMode => {
  if (!localStorage.getItem('colorMode')) {
    localStorage.setItem('colorMode', 'light');
  }
  return localStorage.getItem('colorMode') as PaletteMode;
};

/* eslint-disable @typescript-eslint/no-empty-function */
const UIContext = React.createContext({
  isMobile: false,
  colorMode: 'light' as PaletteMode,
  isSmallDevice: false,
  isLargeDevice: false,
  iOS: false,
  openSideNav: false,
  toggleDrawer: (open: boolean) => {},
  isMediumDevice: true,
  animationsDisabled: true,
});
/* eslint-enable @typescript-eslint/no-empty-function */

const UIContextProvider = ({ children }: { children: JSX.Element }) => {
  const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);
  const dimensionsTheme = useTheme();
  const isSmallDevice = useMediaQuery(dimensionsTheme.breakpoints.down(360));
  const isMobile = useMediaQuery(dimensionsTheme.breakpoints.down('sm'));
  const isMediumDevice = useMediaQuery(dimensionsTheme.breakpoints.up('md'));
  const isLargeDevice = useMediaQuery(dimensionsTheme.breakpoints.up('lg'));
  const [openSideNav, setOpenSideNav] = useState(isLargeDevice);
  const toggleDrawer = useCallback((open: boolean) => setOpenSideNav(open), []);
  const [colorMode, setColorMode] = useState<PaletteMode>(getColorMode());
  const toggleColorMode = () => {
    setColorMode((prevState: PaletteMode) => {
      const newState = prevState === 'light' ? 'dark' : 'light';
      localStorage.setItem('colorMode', newState);
      return newState;
    });
  };

  const contextValue = useMemo(() => ({
    isMobile,
    colorMode,
    isSmallDevice,
    isLargeDevice,
    animationsDisabled: false,
    iOS,
    openSideNav,
    toggleDrawer,
    isMediumDevice,
  }), [isMobile, colorMode, isSmallDevice, isLargeDevice, iOS, openSideNav, toggleDrawer, isMediumDevice]);
  return <UIContext.Provider value={contextValue}>{children}</UIContext.Provider>;
};

export { UIContext, UIContextProvider };