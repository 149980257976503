import React, {useContext} from 'react';
import {CSSObject, styled, Theme} from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import {UIContext} from '../context/UIContext';

const appBarStylings = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundImage: 'none',
  backgroundColor: theme.palette.background.paper,
  boxShadow: 'none',
  borderBottom: `1px ${theme.palette.grey[200]} solid`,
  [theme.breakpoints.up('sm')]: {
    borderLeft: `1px ${theme.palette.grey[200]} solid`,
  },
});

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) =>
    prop !== 'open' && prop !== 'drawerWidth' && prop !== 'closedDrawerWidth',
})<{ drawerWidth: number; closedDrawerWidth: number; open?: boolean }>(
  ({ theme, open, drawerWidth, closedDrawerWidth }) => ({
    ...appBarStylings(theme),
    ...(!open && {
      width: `calc(100% - ${closedDrawerWidth}px)`,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  })
);

const TopBar = ({
  children,
  drawerWidth,
  closedDrawerWidth,
}: {
  children: JSX.Element;
  drawerWidth: number;
  closedDrawerWidth: number;
}) => {
  const { openSideNav } = useContext(UIContext);

  return (
    <AppBar
      position="fixed"
      open={openSideNav}
      drawerWidth={drawerWidth}
      closedDrawerWidth={closedDrawerWidth}
    >
      <Toolbar
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingLeft: { xs: 0, sm: '24px' },
        }}
      >
        {children}
      </Toolbar>
    </AppBar>
  );
};
export default TopBar;
