import React, {useContext} from 'react';
import {Box, Divider, Grid, IconButton, Menu, styled, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import Link from '@mui/material/Link';
import PersonIcon from '@mui/icons-material/Person';
import DashboardIcon from '@mui/icons-material/Dashboard';
import StackedBarChartIcon from '@mui/icons-material/StackedBarChart';
import MonitorIcon from '@mui/icons-material/Monitor';
import CloseIcon from '@mui/icons-material/Close';
import MicrowaveIcon from '@mui/icons-material/Microwave';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import AppShortcutIcon from '@mui/icons-material/AppShortcut';
import WebIcon from '@mui/icons-material/Web';

import {Theme} from "@mui/material/styles";
import {slugify} from '../helpers/helpers';
import {UIContext} from '../context/UIContext';

const IconBox = styled(Box)(({theme}) => ({
    width: 'fit-content',
    display: 'flex',
    margin: 'auto',
    padding: '8px',
    borderRadius: '28px',
    ':hover': {
        backgroundColor: theme.palette.grey[100],
    },
}));

const AppsMenu = ({
                      open,
                      handleClose,
                      anchorEl,
                  }: {
    open: boolean;
    handleClose: () => void;
    anchorEl: null | HTMLElement;
}) => {
    const {t} = useTranslation();
    const {animationsDisabled} = useContext(UIContext);
    const iconWidth = 32;
    const iconHeight = 32;
    const menuStyle = {
        overflow: 'visible',
        maxWidth: 300,
        backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.10), rgba(255, 255, 255, 0.10))',
        filter: (theme: Theme) => `drop-shadow(0px 2px 8px ${theme.palette.grey[400]})`,
        mt: 1.5,
        '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
        },
        '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: {xs: 19, sm: 22},
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            backgroundImage:
                'linear-gradient(rgba(255, 255, 255, 0.10), rgba(255, 255, 255, 0.10))',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
        },
    };
    const apps = [
        {
            title: t('appsMenu.myAccount'),
            href: process.env.REACT_APP_LINK_MANAGE_ACCOUNT,
            icon: <PersonIcon sx={{color: 'primary.main', width: iconWidth, height: iconHeight}}/>,
        },
        {
            title: t('appsMenu.backOffice'),
            href: process.env.REACT_APP_LINK_BACK_OFFICE,
            icon: <DashboardIcon sx={{color: 'text.disabled', width: iconWidth, height: iconHeight}}/>,
        },
        {
            title: t('appsMenu.reporting'),
            href: process.env.REACT_APP_LINK_REPORTING,
            icon: (
                <StackedBarChartIcon
                    sx={{color: 'text.disabled', width: iconWidth, height: iconHeight}}
                />
            ),
        },
        {
            title: t('appsMenu.till'),
            href: process.env.REACT_APP_LINK_TILL,
            icon: <MonitorIcon sx={{color: 'text.disabled', width: iconWidth, height: iconHeight}}/>,
        },
        {
            title: t('appsMenu.kds'),
            href: process.env.REACT_APP_LINK_TILL,
            icon: <MicrowaveIcon sx={{color: 'text.disabled', width: iconWidth, height: iconHeight}}/>,
        },
        {
            title: t('appsMenu.support'),
            href: process.env.REACT_APP_LINK_SUPPORT,
            icon: (
                <SupportAgentIcon sx={{color: 'text.disabled', width: iconWidth, height: iconHeight}}/>
            ),
        },
        {
            title: t('appsMenu.appStore'),

            href: process.env.REACT_APP_LINK_APP_STORE,
            icon: (
                <AppShortcutIcon sx={{color: 'text.disabled', width: iconWidth, height: iconHeight}}/>
            ),
        },
        {
            title: t('appsMenu.webStore'),
            href: process.env.REACT_APP_LINK_WEB_STORE,
            icon: <WebIcon sx={{color: 'text.disabled', width: iconWidth, height: iconHeight}}/>,
        },
    ];

  return (
      <Menu
          anchorEl={anchorEl}
          id="profile-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          MenuListProps={{sx: {padding: 0}}}
          transitionDuration={animationsDisabled ? 1 : 'auto'}
          PaperProps={{
              elevation: 0,
              sx: menuStyle,
          }}
          transformOrigin={{horizontal: 'right', vertical: 'top'}}
          anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
      >
          <Box sx={{flexDirection: 'row', padding: 2}}>
              <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                  <Typography variant="h2">Apps</Typography>
                  <IconButton
                      title={t('appsMenu.close')}
                      aria-label={t('appsMenu.close')}
                      color="inherit"
                      size="small"
                      onClick={handleClose}
                      sx={{padding: 0}}
                      role="button"
                  >
                      <CloseIcon
                          sx={{
                              color: (theme) => `${theme.palette.grey[300]}`,
                          }}
                      />
                  </IconButton>
              </Box>
              <Divider
                  sx={{
                      margin: '16px -16px',
                  }}
              />
              <Grid container spacing={2}>
                  {apps.map((app) => (
                      <Grid key={`app-link-${slugify(app.title)}`} item xs={4}>
                          <Link
                              href={app.href}
                              rel="noreferrer noopener"
                              color="text.primary"
                              title={app.title}
                              aria-label={app.title}
                              sx={{flex: 1, textDecoration: 'none'}}
                          >
                              <Box
                                  sx={{
                                      flexDirection: 'column',
                                      alignContent: 'center',
                                      alignItems: 'center',
                                      textAlign: 'center',
                                      flex: 1,
                                  }}
                              >
                                  <IconBox>{app.icon}</IconBox>
                                  <Typography variant="body2">{app.title}</Typography>
                              </Box>
                          </Link>
                      </Grid>
                  ))}
              </Grid>
          </Box>
      </Menu>
  );
};

export default AppsMenu;
