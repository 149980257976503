/* eslint-disable import/prefer-default-export */
import { DateLocale } from 'yup/lib/locale';
import { User } from '../context/UserContext';
import { apiFetch } from './fetch';

export type ConnDetails = {
  databasePassword: string;
  username: string;
  windowsPassword: string;
};

export const getDatabaseConnDetails = async ({ id }: { id: string }): Promise<ConnDetails> => {
  const response = await apiFetch<ConnDetails>(`/api/database-conn-details/${id}`);
  return response;
};

