import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Divider,
  Modal,
  TextField,
  Typography,
  Grid,
  Input,
  Stack,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import Link from '@mui/material/Link';

import { WidthFull } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CircularProgress from '@mui/material/CircularProgress';
import { UserContext } from '../../context/UserContext';
import AlertMessage from '../../components/AlertMessage';
import { approveRequest, getRequest, newRequest, RequestsType } from '../../api/requests';
import { ConnDetails, getDatabaseConnDetails } from '../../api/database';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

type RequestParams = {
  id: string;
};

const RequestID = () => {
  const { authenticatedUser, isLoading, userPermissions } = useContext(UserContext);
  const [request, setRequest] = useState<RequestsType>();
  const { id } = useParams<RequestParams>();
  const [credentials, setCredentials] = useState<ConnDetails | undefined>(undefined);
  const [showDatabasePassword, setShowDatabasePassword] = useState<boolean>(false);
  const [showWindowsPassword, setShowWindowsPassword] = useState<boolean>(false);
  const [showUsername, setShowUsername] = useState<boolean>(false);

  const [dbConnOpen, setDbConnOpen] = React.useState(false);
  const [error, setError] = React.useState('');
  const [modalInfo, setModalInfo] = React.useState('');

  const handleDbConnOpen = () => {
    if (!id) return;
    setDbConnOpen(true);
    getDatabaseConnDetails({ id }).then((data) => {
      setCredentials(data);
    });
  };

  const handleDbConnClose = () => {
    setDbConnOpen(false);
    setModalInfo('');
    setCredentials(undefined);
    setShowDatabasePassword(false);
    setShowWindowsPassword(false);
    setShowUsername(false);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  const handleShowDatabasePassword = () => {
    setShowDatabasePassword(!showDatabasePassword);
  };
  const handleShowWindowsPassword = () => {
    setShowWindowsPassword(!showWindowsPassword);
  };
  const handleShowUsername = () => {
    setShowUsername(!showUsername);
  };

  useEffect(() => {
    if (!id) return;
    getRequest({ id }).then((res) => {
      setRequest(res);
    });
  }, [id]);

  return (
    <Box sx={{ width: 'auto', p: 4 }}>
      {error && <AlertMessage type="error"> {error} </AlertMessage>}
      {request && (
        <Card sx={{ minWidth: '400px' }}>
          <CardContent>
            <Box sx={{ display: 'flex', gap: 4, alignItems: 'center' }}>
              <Typography variant="h5" component="div">
                {request.Target}
              </Typography>
              <Typography variant="overline" color="text.secondary">
                ({request.CreatedAt})
              </Typography>
            </Box>
            <Divider sx={{ mb: 1, mt: 1 }} />
            <Typography variant="body2">Status: {request.Status}</Typography>
            <Typography variant="body2" gutterBottom>
              Acess request by: {request.User.email}
            </Typography>
            <Typography variant="body2">Valid until: {request.ValidUntil}</Typography>
            <Box sx={{ py: 2 }}>Reason: {request.Reason}</Box>
          </CardContent>
          <CardActions>
            {request.Status === 'Requested' && (
              <>
                <Button
                  size="small"
                  color="success"
                  disabled={!userPermissions.canAllowAccesDatabase}
                  onClick={() =>
                    approveRequest({ id: request.ID })
                      .then((response) => setRequest(response))
                      .catch((response) => setError(response))
                  }
                >
                  Approve
                </Button>
                <Button size="small" color="error" disabled>
                  Delete
                </Button>
              </>
            )}
            <Button
              size="small"
              onClick={() => handleDbConnOpen()}
              disabled={request.Status !== 'Approved'}
            >
              Get connection details
            </Button>
          </CardActions>
        </Card>
      )}

      <Modal open={dbConnOpen} onClose={handleDbConnClose}>
        <Box sx={modalStyle}>
          {modalInfo && <AlertMessage type="info"> {modalInfo} </AlertMessage>}
          {!modalInfo && (
            <AlertMessage type="info">
              <Typography>
                To use this credentials you need to do it from a Bastion host.{' '}
                <Link href="https://docs.eposnow.io/development/platform/database/productiondb/">
                  More information.
                </Link>
              </Typography>
            </AlertMessage>
          )}
          {!credentials && (
            <Container
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                pt: 1,
                minHeight: '128px',
              }}
            >
              <Box>
                <CircularProgress />
              </Box>
            </Container>
          )}
          {credentials && (
            <Stack sx={{ pt: 1 }}>
              <Stack direction="row" spacing={2}>
                <Box sx={{ width: 100 }}>
                  <Typography variant="h6" component="h2">
                    Username
                  </Typography>
                </Box>
                <Box sx={{ width: 188 }}>
                  <Input
                    type={showUsername ? 'text' : 'password'}
                    value={credentials.username}
                    readOnly
                    fullWidth
                  />
                </Box>
                <Box>
                  <IconButton
                    aria-label="Copy Username"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        credentials.username ? credentials.username : ''
                      );
                      setModalInfo('Username copied');
                    }}
                  >
                    <ContentCopyIcon />
                  </IconButton>
                  <IconButton aria-label="Show username" onClick={() => handleShowUsername()}>
                    <VisibilityIcon />
                  </IconButton>
                </Box>
              </Stack>
              <Stack direction="row" spacing={2}>
                <Box sx={{ width: 100 }}>
                  <Typography variant="h6" component="h2">
                    Win pass
                  </Typography>
                </Box>
                <Box sx={{ width: 188 }}>
                  <Input
                    type={showWindowsPassword ? 'text' : 'password'}
                    value={credentials.windowsPassword}
                    readOnly
                    fullWidth
                  />
                </Box>
                <Box>
                  <IconButton
                    aria-label="Copy bation host password"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        credentials.windowsPassword ? credentials.windowsPassword : ''
                      );
                      setModalInfo('Bation host password copied');
                    }}
                  >
                    <ContentCopyIcon />
                  </IconButton>
                  <IconButton
                    aria-label="Show bation host password"
                    onClick={() => handleShowWindowsPassword()}
                  >
                    <VisibilityIcon />
                  </IconButton>
                </Box>
              </Stack>
              <Stack direction="row" spacing={2}>
                <Box sx={{ width: 100 }}>
                  <Typography variant="h6" component="h2">
                    DB pass
                  </Typography>
                </Box>
                <Box sx={{ width: 188 }}>
                  <Input
                    type={showDatabasePassword ? 'text' : 'password'}
                    value={credentials.databasePassword}
                    readOnly
                    fullWidth
                  />
                </Box>
                <Box>
                  <IconButton
                    aria-label="Copy database password"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        credentials.databasePassword ? credentials.databasePassword : ''
                      );
                      setModalInfo('Database password copied');
                    }}
                  >
                    <ContentCopyIcon />
                  </IconButton>
                  <IconButton
                    aria-label="Show database password"
                    onClick={() => handleShowDatabasePassword()}
                  >
                    <VisibilityIcon />
                  </IconButton>
                </Box>
              </Stack>
            </Stack>
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default RequestID;

