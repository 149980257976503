import React, { useState } from 'react';
import { Box } from '@mui/material';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter as Router } from 'react-router-dom';
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import ThemeContext from './context/ThemeContext';
import { UserContext, UserContextProvider } from './context/UserContext';
import { UIContextProvider } from './context/UIContext';
import AppRoutes from './AppRoutes';
import TopNav from './components/TopNav';
import SideNav from './components/SideNav';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      refetchOnMount: true,
      retry: false,
      staleTime: 60 * 60 * 1000, // @TODO change to a different time
    },
  },
})   

export const AppLinks = [
  {text: 'home', path: '/', icon: <HomeOutlinedIcon/>},
  {text: 'settings', path: '/settings', icon: <SettingsOutlinedIcon/>},
];

export const StaticAppLinks = [
  {text: 'Home', path: '/', icon: <HomeOutlinedIcon/>},
  {text: 'Settings', path: '/settings', icon: <SettingsOutlinedIcon/>},
];



const App = () => (
    <UIContextProvider>
      <ThemeContext>
        <QueryClientProvider client={queryClient}>
          <UserContextProvider>
              <Router>
                <Box
                  sx={{
                    display: 'flex',
                  }}
                >
                  <TopNav />
                  <SideNav />
                  <Box sx={{mt:'64px', flexGrow:1}}>
                  <AppRoutes />
                  </Box>
                </Box>
              </Router>
          </UserContextProvider>
        </QueryClientProvider>
      </ThemeContext>
    </UIContextProvider>
  );

export default App;

