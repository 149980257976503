import React from 'react';
import {Box, Typography} from '@mui/material';
import NavigationBreadcrumb, {NavigationBreadcrumbItem} from "./NavigationBreadcrumb";

export interface MainHeaderProps {
    children?: React.ReactNode;
    title: string;
    size?: string;
    nav?: NavigationBreadcrumbItem[];
}

const MainHeader = ({children, title, size, nav}: MainHeaderProps) => (
    <Box
        sx={{
            marginLeft: 0,
            marginBottom: 3,
            padding: {xs: '0px 8px', sm: 0}
        }}
    >
        {nav && <NavigationBreadcrumb items={nav}/>}
        <Typography variant={size === 'large' ? 'h1' : 'h2'} sx={{marginBottom: size === 'large' ? 2 : 1}}>
            {title}
    </Typography>
    {children}
  </Box>
);

export default MainHeader;
