import React, {CSSProperties, ReactElement} from "react";
import {Breadcrumbs, Link} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

export interface NavigationBreadcrumbItem {
    icon?: ReactElement;
    text: string;
    link?: string;
}

export interface NavigationBreadcrumbProps {
    items: NavigationBreadcrumbItem[];
}

const linkStyle: CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '150%',
    letterSpacing: '0.15px'
};

export const NavigationBreadcrumb = (props: NavigationBreadcrumbProps) => {
    const {items} = props;
    return <Breadcrumbs separator={<ChevronRightIcon fontSize="small"/>} sx={{marginBottom: "16px"}}>
        {items.map((item) => (<Link key={item.text} underline="hover" sx={linkStyle} color="inherit" href={item.link}>
            {item.icon}
            {item.text}
        </Link>))}
    </Breadcrumbs>;
}

export default NavigationBreadcrumb;
