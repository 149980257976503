import React, { SVGProps } from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Link,
  Typography,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const InfoCard = ({
  children,
  title,
  subheader,
  Logo,
  buttonText,
  width,
  path,
  href,
}: {
  children?: React.ReactNode;
  title: string;
  subheader?: string;
  Logo?: React.FunctionComponent<SVGProps<SVGSVGElement> & { title?: string | undefined }>;
  buttonText?: string;
  width: number;
  path?: string;
  href?: string;
}) => (
  <Grid item xs={12} md={width}>
    <Card
      variant="outlined"
      sx={{
        borderRadius: 2,
        border: (theme) => `1px ${theme.palette.grey[200]} solid`,
      }}
    >
      <CardContent
        sx={{
          padding: 3,
          ':last-child': {
            paddingBottom: 1,
          },
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={Logo ? 8 : 12}>
            <CardHeader
              title={title}
              titleTypographyProps={{ variant: 'h3' }}
              sx={{
                padding: 0,
                marginBottom: 2,
              }}
            />
            <Typography
              sx={{
                color: (theme) => theme.palette.grey[300],
              }}
            >
              {subheader}
            </Typography>
          </Grid>
          {Logo && (
            <Grid item xs={4}>
              <Logo
                style={{
                  display: 'block',
                  maxWidth: '100%',
                  marginLeft: 'auto',
                  fontSize: '80px'
                }}
              />
            </Grid>
          )}
        </Grid>
        {children}
      </CardContent>

      {buttonText && (path || href) && (
        <>
          <Divider />
          <CardActions
            sx={{
              padding: 1,
            }}
          >
            {path && (
              <Button
                component={RouterLink}
                to={path}
                sx={{
                  paddingRight: 2,
                  paddingLeft: 2,
                }}
              >
                {buttonText}
              </Button>
            )}
            {href && (
              <Button
                component={Link}
                href={href}
                target="_blank"
                sx={{
                  paddingRight: 2,
                  paddingLeft: 2,
                }}
              >
                {buttonText}
              </Button>
            )}
          </CardActions>
        </>
      )}
    </Card>
  </Grid>
);

export default InfoCard;

