/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, Dispatch } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { getUser } from '../api/users';
import { SideLink } from '../components/SideNav';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const defaultUseState: Dispatch<any> = () => {};

const SUCCESS_MESSAGE_TIME = 2000;

const links: { guest: SideLink[]; developer: SideLink[]; administrator: SideLink[] } = {
  guest: [
    { text: 'Home', path: '/', icon: <HomeOutlinedIcon /> },
    { text: 'Requests', path: '/requests', icon: <AssignmentOutlinedIcon /> },
  ],
  developer: [],
  administrator: [],
};

const getNavLinks = (role: string): SideLink[] => {
  switch (role) {
    case 'guest':
      return links.guest;
    case 'developer':
      return [...links.developer, ...links.guest];
    case 'administrator':
      return [...links.administrator, ...links.developer, ...links.guest];
    default:
      return links.guest;
  }
};

export type User = {
  ID: string;
  email: string;
  role: string;
};

export type Permissions = {
  canRequestReadProductionDatabase?: boolean;
  canRequestWriteProductionDatabase?: boolean;
  canAllowAccesDatabase?: boolean;
};

const mockAuthenticatedUser = {
  id: 1,
  email: 'james.higgins@eposnow.com',
  role: 'guest',
};

const emptyPermissions: Permissions = {};

const UserContext = React.createContext({
  authenticatedUser: { ...mockAuthenticatedUser },
  userPermissions: emptyPermissions,
  setAuthenticatedUser: defaultUseState,
  fetchData: () => {},
  isError: false,
  isLoading: true,
  navItems: getNavLinks('guest'),
});

const UserContextProvider = ({ children }: { children: JSX.Element }) => {
  const queryClient = useQueryClient();
  // @TODO - when we have authenticated routes, this should be dropped and use the deconstruct from useQuery object. like: { data: authenticatedUser } = useQuery

  const [authenticatedUser, setAuthenticatedUser] = useState(mockAuthenticatedUser);
  const [showSuccessEditUser, setShowSuccessEditUser] = useState(false);
  const [errorMessageEditUser, setErrorMessageEditUser] = useState<string>('');
  const [userPermissions, setPermissions] = useState<Permissions>(emptyPermissions);
  const [navItems, setNavItems] = useState<SideLink[]>(getNavLinks('guest'));

  const {
    refetch: fetchData,
    isLoading,
    isError,
  } = useQuery('user', getUser, {
    onSuccess: (data) => {
      setAuthenticatedUser(data.user);
      setNavItems(getNavLinks(data.user.role));
      setPermissions(data.permissions);
    },
    onError: () => {
      setAuthenticatedUser({
        ...authenticatedUser,
      });
    },
  });

  const contextValue = {
    authenticatedUser,
    setAuthenticatedUser,
    userPermissions,
    fetchData,
    isLoading,
    isError,
    showSuccessEditUser,
    errorMessageEditUser,
    navItems,
  };

  return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
};

export { UserContext, UserContextProvider };
/* eslint-enable @typescript-eslint/no-empty-function */

